import Cookies from "universal-cookie";
import { getAccessTokenUP, getAccessTokenRT } from "./AuthHandling";
import { calender } from "../config";
const cookies = new Cookies();

export const sensorConfig = async (start, end, setCalenderEntries, setSuccess) =>{
    let token = cookies.get('access_token');
    console.log(token);
    if(token == '') return;
    let res = await fetch(`https://api.beta.thing-it.com/sensor-data-v1/meshes/6053161e73742241ae5edb94/sensor-data-export?rangeStartingAt=2023-10-10T00:00:00.000,rangeEndingAt=2023-10-10T23:00:00.000`,{
        method: "GET",
        mode: 'no-cors',
        headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json, text/plain",
            "Access-Control-Allow-Origin": '*'
        }
    });
    console.log(res.status)
    if(res.status === 401){
        console.log("UNAUTHORIZED")
        let tokenNew = await getAccessTokenUP();
        if(token) return sensorConfig(start, end, setCalenderEntries, setSuccess);
        return console.error("NO ACCOUNT IN HERE!")
    }
    if(res.status !== 200) return {entries: [], success: false};
    let entries = await res.json();
    setSuccess(true)
    console.log(entries)
  
    return {entries, success:true};
  }