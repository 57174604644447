import { useEffect, useState } from 'react';
import CalenderEntry from '../Elements/CalenderEntry';
import './BookingFrame.css'
import Clock from '../Elements/Clock';
import { getCurrentEvent } from '../00_Function/CalenderEntries';
import CurrentEvent from '../Elements/CurrentEvent';
import FreeSlot from '../Elements/FreeSlot';
import { fillEntriesWithFreeSlots } from '../00_Function/CalenderEntries';
import CurrentlyFree from '../Elements/CurrentlyFree';
import logo from "../Images/Logo.png";


const filterEntries = entries => {
    if (entries.length == 0) return [];
    let filteredEntries = entries.filter((item, index, array) => {
        let endEvent = new Date (Date.parse(item.end));
        let now = new Date();
        //return true;
        return now < endEvent;
    })
    return filteredEntries;
}

const noEntriesToday = (setFilteredEntries, setCurentEvent, businessHours) => {
    console.log({setCurentEvent, setFilteredEntries, businessHours})
    setCurentEvent(null);
    let start = new Date();
    let end = new Date()
    end.setHours(18, 0);
    setFilteredEntries([{
        start: start,
        end: end,
        available: true
    }]);
    return;
}

const BookingFrame = props => {
    let [date, setDate] = useState("");
    let [filteredEntries, setFilteredEntries] = useState([]);
    let [currentEvent, setCurentEvent] = useState([]);
    let [occupied, setOccupied] = useState(false);
    let [entriesWithBlanks, setEntriesWithBlanks] = useState([]);
    let [ready, setReady] = useState(false);
    let [calLabel, setCalLabel] = useState("");
    let [showSelection, setShowSelection] = useState(false);
    let entries = props.entries;

    useEffect(()=> {
//        if(props.activeCalendar == null) return;
//        if(props.businessHours.endDate == null) return
        if(props.entries.length == 0) {
            noEntriesToday(setFilteredEntries, setCurentEvent, props.businessHours);
            return setReady(true)
        }
        let startDate = new Date(Date.parse(props.entries[0].start));
        setDate(`${startDate.toLocaleDateString([], {day:"2-digit", month:"2-digit", year:"numeric"})}`)
        let filterEn = filterEntries(props.entries);
        console.log({filterEn})
        if(props.entries == null) return;
        setFilteredEntries(filterEn);
        setCurentEvent(getCurrentEvent(props.entries));
        setReady(true);
        
    },[props.entries])

    useEffect(()=>{
        if(!ready) return;
        console.log({filteredEntries})
        //if(Object.keys(props.businessHours).length == 0 )return;
        let filledEntries = fillEntriesWithFreeSlots(filteredEntries, props.businessHours);
        console.log(filledEntries);
        setEntriesWithBlanks(filledEntries);
        
      }, [filteredEntries, props.businessHours])

    useEffect(()=>{
        console.log({currentEvent, occupied})
        if (currentEvent == null) return setOccupied(false);
        return setOccupied(true);
    }, [currentEvent])
    if(entriesWithBlanks.length == 0) return <></>
    return (<div className='totalFrame'>
        <div className='leftPanel'>
            
            <h1>{props.calendarLabel}</h1>
            
            {
                occupied ? 
                <CurrentEvent 
                    currentEvent={currentEvent}
                    time ={props.time}/> : 
                <CurrentlyFree 
                    setSuccess={props.setSuccess}
                    setEntries={props.setEntries}
                    time ={props.time}
                    activeCalendar={props.activeCalendar}
                    /> 
            }
        </div>
        <div className='rightPanel'>
            <h2>Kommende Buchungen am {date}</h2>
            <div className='bookingFrame'>
                {entriesWithBlanks.map((item, index, array) => Object.keys(item).includes('available') ? <FreeSlot entry={item} key={index} /> : <CalenderEntry entry={item} key={index}/>)}
            </div>
        </div>
        <img className='logo' src={logo}/>

    </div>)
}

export default BookingFrame;