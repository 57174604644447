import { useEffect, useState } from 'react';
import './CalenderEntry.css'

const CalenderEntry = props => {
    let [startTime, setStartTime] = useState("");
    let [endTime, setEndTime] = useState("");
    let [user, setUser] = useState("");
    useEffect(()=>{
       let startDate = new Date(Date.parse(props.entry.start));
       let endDate = new Date(Date.parse(props.entry.end));
       setStartTime(`${startDate.toLocaleTimeString([], {hour:"2-digit", minute:"2-digit"})}`);
       setEndTime(`${endDate.toLocaleTimeString([], {hour:"2-digit", minute:"2-digit"})}`);
       let user = `${props.entry.bookingUser.firstName} ${props.entry.bookingUser.lastName}`
        setUser(user);
    }, []);
    return (<div className='calenderEntry'>
        <p>{startTime} - {endTime}</p>
        <p>{user}</p>
    </div>)
}

export default CalenderEntry;