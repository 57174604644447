import { useEffect, useState } from 'react';
import './Clock.css'

const Clock = props => {
    let timeTest = props.time;

    return (<div className='clock'>
        <h1 className=''>{timeTest}</h1>
    </div>)
}

export default Clock;