import { useEffect, useState } from 'react';
import './FreeSlot.css'

const FreeSlot = props => {
    let [startTime, setStartTime] = useState("");
    let [endTime, setEndTime] = useState("");
    useEffect(()=>{
       let startDate = new Date(Date.parse(props.entry.start));
       let endDate = new Date(Date.parse(props.entry.end));
       setStartTime(`${startDate.toLocaleTimeString([], {hour:"2-digit", minute:"2-digit"})}`);
       setEndTime(`${endDate.toLocaleTimeString([], {hour:"2-digit", minute:"2-digit"})}`);
    }, []);
    return (<div className='freeSlot'>
        <p>{startTime} - {endTime}</p>
        <p>Frei</p>
    </div>)
}

export default FreeSlot;