import Cookies from "universal-cookie"

let cookies = new Cookies();

let url = 'https://www.thing-it.com/customers/62348e2471b91833fbe6f30e/meshes/62348eaa48cf6a272513bef3/tasks/4503599687608037/complete?customer=62348e2471b91833fbe6f30e&mesh=62348eaa48cf6a272513bef3'
let startUrl = 'https://www.thing-it.com/customers/62348e2471b91833fbe6f30e/meshes/62348eaa48cf6a272513bef3/flows/schadensfall/start?customer=62348e2471b91833fbe6f30e&mesh=62348eaa48cf6a272513bef3'

let mesh = { "Mesh": {
    "_id": "62348eaa48cf6a272513bef3",
    "uuid": "ae55af40-a6c2-11ec-b095-d360eb74ea96",
    "id": "7m.concepts",
    "label": "7m.concepts"
}}


let payload = {
    "Mesh": {
        "_id":"62348eaa48cf6a272513bef3",
        "uuid":"ae55af40-a6c2-11ec-b095-d360eb74ea96",
        "id":"7m.concepts",
        "label":"7m.concepts"
    },
    "Incident":{
        "severity":"High",
        "location": {
            "siteUuid":"d7118c7f-a74b-44f0-8173-826e73d37e27",
            "siteName":"Königswinter",
            "buildingUuid":"5b7baa2c-f764-409d-a2bb-920065cccfae",
            "buildingName":"Hauptgebäude",
            "floorUuid":"1ff0b981-5157-48ca-b7a1-fcb72c5575eb",
            "floorName":"1",
            "spaceUuid":"44a9c104-f3f7-4b6c-aed1-65bf24a71c41",
            "spaceName":"2.2",
            "assetUuid":null,
            "assetLabel":null,
            "indoorLocation":"2.2, 1, Hauptgebäude, Königswinter"
        },
        "category":"Klimaanlage",
        "categoryLabel":"Klimaanlage",
        "description":"TestFromReact",
        "synopsis":"Testicles",
        "reportingUser":{
            "firstName":"Unknown",
            "lastName":"Unknown",
            "eMail":"Unknown",
            "costCenter":"Unknown",
            "cellPhone":"Unknown"
        }
    }
}

export const startTask = async () => {
    let access_token = cookies.get('access_token');
    await fetch(startUrl, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${access_token}}]`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(mesh)
    }).then( res => {
        console.log(res.status);
        if(res.status === 200) return console.log("SUCCESS")
        console.log(res.text())
    }).then(() => {
        return;
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${access_token}}]`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).then( res => {
            console.log(res.status);
            if(res.status === 200) return console.log("SUCCESS")
            console.log(res.text())
        })
     
    })
}
    