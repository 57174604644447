import { useEffect, useState } from 'react';
import CalenderEntry from './Elements/CalenderEntry';
import './App.css';
import {getAccessTokenUP} from './00_Function/AuthHandling';
import { fillEntriesWithFreeSlots, getCalenders, getTodaysEntries } from './00_Function/CalenderEntries';
import { getCalenderEntries } from './00_Function/CalenderEntries';
import BookingFrame from './Frames/BookingFrame';
import Login from './Frames/Login';
import { startTask } from './00_Function/startProcess';
import { sensorConfig } from './00_Function/SensorState';
import { calender } from './config';
import lort from "./Images/LORT.jpg";
import eort from "./Images/EORT.jpeg";
import sort from "./Images/SORT.jpeg";
import {FaList} from 'react-icons/fa'
  


function App() {
  let [token, setToken] = useState("");
  let [user, setUser] = useState("");
  let [refreshToken, setRefreshToken] = useState("")
  let [calendarEntries, setCalenderEntries] = useState([])
  let [success, setSuccess] = useState(false);
  let [calendarInfos, setCalendarInfos] = useState([]);
  let [businessHours, setBusinessHours] = useState({})
  let [time, setTime] = useState();
  let [activeCalendar, setActiveCalendar] = useState(null)
  let [showSelect, setShowSelect] = useState(false)
  
  let updateTime = setInterval(() => {
    let now = new Date();
    let time = now.toLocaleTimeString([], {minute: "2-digit", hour:"2-digit", second: "2-digit"})
    setTime(time);
  }, 1000);  
  
  useEffect(()=>{
    if(user.length === 0) return;
    let {todayStart, todayEnd} = getTodaysEntries();
    getCalenders(setCalendarInfos);
  },[user])

  useEffect(()=>{
    console.log({calendarInfos})
    if(calendarInfos.length === 0) return;
    setActiveCalendar(calendarInfos.find(item => item.id === calender))
    console.log({activeCalendar})
    if (activeCalendar == null) return;
    let businesshoursEndToday = new Date(Date.now());

    businesshoursEndToday.setMinutes(parseInt(activeCalendar.businessHours.end.minute));
    businesshoursEndToday.setHours(parseInt(activeCalendar.businessHours.end.hour));
    businesshoursEndToday.setSeconds(0);

    let businesshoursStartToday = new Date(Date.now());
    
    businesshoursStartToday.setMinutes(parseInt(activeCalendar.businessHours.start.minute));
    businesshoursStartToday.setHours(parseInt(activeCalendar.businessHours.start.hour));
    businesshoursStartToday.setSeconds(0);
    setBusinessHours({businessHours:activeCalendar.businessHours, endDate: businesshoursEndToday, startDate: businesshoursStartToday})
    
    console.log({businesshoursEndToday, businesshoursStartToday})
  }, [calendarInfos]);


useEffect(() => {
  console.log({activeCalendar})
  if (activeCalendar == null) return;
  getCalenderEntries(setCalenderEntries, setSuccess, false, activeCalendar.id);

  let App = document.getElementsByClassName("App")[0];
  console.log({App})
  if(activeCalendar.label==="Einsatzort")App.style.backgroundImage = `url(${eort})`;
  if(activeCalendar.label==="Lieblingsort")App.style.backgroundImage = `url(${lort})`;
  if(activeCalendar.label==="Standort")App.style.backgroundImage = `url(${sort})`;
  console.log(calendarEntries);
}, [activeCalendar])

  return (
    <div className="App">
      {success ? 
        <BookingFrame 
          entries={calendarEntries} 
          setEntries={setCalenderEntries} 
          setSuccess={setSuccess}
          businessHours={businessHours}
          time={time}
          calendarInfos={calendarInfos}
          calendarLabel={activeCalendar.label}
          activeCalendar={activeCalendar}
          /> : 
        <Login setUser={setUser}/>}
        { showSelect ?
        <div className='selectRoom'>
          <select 
            
            onChange={e => {
              setActiveCalendar(calendarInfos.find(item => item.id === e.target.value))
              }}
          >
            {calendarInfos.map((item, index) => <option
              value={item.id}
            >{item.label}</option>)}
          </select>
          <button onClick={() => setShowSelect(false)}>Hide</button>
          </div> :
          <FaList 
            className='selectRoom opacity05'
            onClick={() => setShowSelect(true)}  
          ></FaList>
        }
    </div>
  );
}

export default App;
