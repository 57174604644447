import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
const cookies = new Cookies();

export const getAccessTokenUP = async () => {
    let loginBody = {
        "grant_type": "password",
        "username": "kiosk@metropol-ti.de",
        "password": "iDbxFWJDc2H3xLQ!"
    }
    let res = await fetch("https://www.thing-it.com/portal/api/oauth2/token", {
        method: "POST",
        headers:{
            "Authorization": "Basic NWVhNzIwZDItODllMi00NjZmLTk2ZTMtMDRhMWNkMDQyNDYxOmY1MDY2OWUzLTI1NzEtNGYwMC05ZTMyLWI0OWFhYjA1OGY1OQ== ",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(loginBody)
    });
    if(res.status !== 200) {
        console.error(res.status);
        console.error(await res.text());
        return false;
    }

    let tokens = await res.json();
    let decoded = jwtDecode(tokens.access_token);
    console.log(decoded);
    cookies.set('access_token', tokens.access_token, {path: '/', expires: Date.parse(decoded.exp)});
    cookies.set('refresh_token', tokens.refresh_token, {path: '/'});
    return decoded.sub;
  }


export const getAccessTokenRT = async () => {
    let refresh_token = cookies.get('refresh_token');
    let loginBody = {
        "grant_type": "token",
        "refresh_token": refresh_token,
        "scope": "*:*"
    }
    let res = await fetch("https://www.thing-it.com/portal/api/oauth2/token", {
        method: "POST",
        headers:{
            "Authorization": "Basic NWVhNzIwZDItODllMi00NjZmLTk2ZTMtMDRhMWNkMDQyNDYxOmY1MDY2OWUzLTI1NzEtNGYwMC05ZTMyLWI0OWFhYjA1OGY1OQ== ",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(loginBody)
    });
    if(res.status !== 200) {
        console.error(res.status);
        console.error(await res.text());
        return false;
    }

    let tokens = await res.json();
    cookies.set('access_token', tokens.access_token, {path: '/'});
    cookies.set('refresh_token', tokens.refresh_token, {path: '/'});
    return true;
  }