import Cookies from "universal-cookie";
import { getAccessTokenUP, getAccessTokenRT } from "./AuthHandling";
import {  validSpaceLabels } from "../config";
const cookies = new Cookies();

let businessHours = {
    businessStart: "",
    businessEnd: ""
}

export const getTodaysEntries = () => {
    let today = new Date(Date.now());
    let todayStart = new Date(today.setHours(0,0)).toISOString();
    let todayEnd = new Date(today.setHours(23,0)).toISOString();
    return {todayStart, todayEnd};
}

export const getCalenders = async (setCalenderInfos) => {
    
    let token = cookies.get('access_token');
    console.log(token);
    if(token == '') return;
    let res = await fetch(`https://www.thing-it.com/customers/6322d583380870b31fbf3a1c/calendars`,{
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json, text/plain"
        }
    });
    console.log(res.status);
    if(res.status === 401){
        console.log("UNAUTHORIZED");
        let tokenNew = await getAccessTokenUP();
        if(token) return getCalenders();
        return console.error("NO ACCOUNT IN HERE!")
    }
    let result = await res.json();
    let spaceCalenders = result.filter((item, index, array)=> validSpaceLabels.includes(item.label));
    console.log(spaceCalenders)
    let calenderInfos =  spaceCalenders.map((item, index, array) => {
        return {            
            label: item.label,
            id: item._id,
            businessHours: item.businessHours            
        }
    })
    console.log({calenderInfos})
    setCalenderInfos(calenderInfos);
}

let timeout = null;

export const getCalenderEntries = async (setCalenderEntries, setSuccess, noReload = 0, calender) =>{
    if (timeout && noReload == 0) clearTimeout(timeout)
    let token = cookies.get('access_token');
    console.log(token);
    let {todayStart, todayEnd} = getTodaysEntries();
    if(token == '') return;
    let res = await fetch(`https://www.thing-it.com/customers/6322d583380870b31fbf3a1c/calendars/${calender}/calendarEntries?start=${todayStart}&end=${todayEnd}`,{
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json, text/plain"
        }
    });
    console.log(res.status)
    if(res.status === 401){
        console.log("UNAUTHORIZED")
        let tokenNew = await getAccessTokenUP();
        if(token) return getCalenderEntries(setCalenderEntries, setSuccess, 0, calender);
        return console.error("NO ACCOUNT IN HERE!")
    }
    if(res.status !== 200) return {entries: [], success: false};
    let entries = await res.json();
    setCalenderEntries(entries);
    if(!noReload) timeout = setTimeout(() => {
        getCalenderEntries(setCalenderEntries, setSuccess, 0, calender).then(({entries, success}) => {
            setCalenderEntries(entries);
        })
    }, 10000)
    
    setTimeout(setSuccess(true), 3000)
    return {entries};
  }

export const book = async (startTime, endTime) => {
    startTime = new Date().toISOString();
    let endTimeDate = new Date(Date.now());
    endTimeDate.setHours(21);
    endTimeDate.setMinutes(0);
    endTime = endTimeDate.toISOString();
    
    console.log ({startTime, endTime});
}  


  export const fillEntriesWithFreeSlots = (entries, businessHours) => {
    console.log({businessHours})
    let filled = []; 
    let now = new Date();
    if(businessHours.endDate == null) businessHours.endDate = new Date();
    if(entries.length === 0){
        console.log("NO ENTRIES")
        filled.push({
                start: now.toISOString(),
                end: businessHours.endDate.toISOString(),
                available: true
        })
        return filled;
    } 


    entries.map((item, index, array) => {
        if (index === 0) {            
           if(Date.parse(item.start) < now) return filled.push(item); // Wenn item.start vor jetzt liegt, dann pushe item
            //Wenn nich, dann pushe erstes blank
            console.log("PUSHE BLANK!")
            filled.push({
                start: now.toISOString(),
                end: item.start,
                available: true
            })
            return filled.push(item);

        }
//        console.log(array[index - 1].end)
        
            let prevItemEnd = new Date(Date.parse(array[index - 1].end));
            let thisItemStart = new Date(Date.parse(item.start));
            if(prevItemEnd < thisItemStart) filled.push({
                start: array[index - 1].end,
                end: item.start,
                available: true
            })
        
        return filled.push(item);
    })
    console.log(filled);
    return filled;
  }

  export const getCurrentEvent = (entries) => {
    if(entries.length === 0) return null;
    let currentEvent = entries.filter((item, index, array) => {
        let now = new Date();
        let startDate = new Date(Date.parse(item.start));
        let endDate = new Date(Date.parse(item.end));
        return (now > startDate) && (now < endDate)
    });
    console.log(currentEvent);
    if(currentEvent.length == 0) return null;
    return currentEvent[0];
}