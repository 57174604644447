import { useEffect, useState } from 'react';
import './CurrentEvent.css'
import Clock from './Clock';

const CurrentEvent = props => {
    let [start, setStart] = useState("");
    let [end, setEnd] = useState("");
    let [user, setUser] = useState("")
    let [title, setTitle] = useState("")

    useEffect(()=>{
        let event = props.currentEvent;
        if(event == null) return;
        let start = new Date(Date.parse(event.start)).toLocaleTimeString([], {hour:"2-digit", minute:"2-digit"})
        let end = new Date(Date.parse(event.end)).toLocaleTimeString([], {hour:"2-digit", minute:"2-digit"})
        let user = `${event.bookingUser.firstName} ${event.bookingUser.lastName}`
        setTitle(event.title);
        console.log({event})
        setStart(start);
        setEnd(end);
        setUser(user);
    }, [props.currentEvent])

    return (<div className='currentEvent'>
        <Clock 
            occupied={true}
            time ={props.time}/>
        <h1>Aktuell Belegt</h1>
        <h3>{title}</h3>
        <div className='eventDetails'>
            <p>Start:</p><p> {start} </p>
            <p>Ende:</p><p> {end}</p>
            <p>Nutzer:</p><p> {user}</p>
        </div>
    </div>)
}

export default CurrentEvent;