import Cookies from "universal-cookie"
import { calender, customer, mesh } from "../config";

let cookies = new Cookies();


export const createBooking = async (startTime, endtime, calender, setErrorOrOK) => {
    let access_token = cookies.get('access_token')
    let url = `https://www.thing-it.com/customers/${customer}/meshes/${mesh}/calendars/${calender}/calendarEntries?customer=${customer}&mesh=${mesh}`
    let payload = {
        assetId: "",
        class: 'Booking',
        end: endtime,
        start: startTime,
        timezone: "Europe/Berlin",
    }
    
    return await fetch(url, {
        method: 'POST',
        headers:{
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    }).then(async res => {
        if(res.status === 200) return setErrorOrOK("OK!");
        console.log(res.status);
        let response = await res.json();
        return setErrorOrOK(response.message);
    })
    
}