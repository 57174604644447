import { useEffect, useRef, useState } from 'react';
import './Login.css'
import { getAccessTokenUP } from '../00_Function/AuthHandling';

const Login = props => {
    let user = useRef()
    let pwd = useRef()
    return (
        <div className='login'>
        <input type="text" ref={user}></input>
        <input type="password" ref={pwd}></input>
        <button type="submit" onClick={async () => {
            let result = await getAccessTokenUP();
            if(result) props.setUser(result);
            return;
        }}>Submit</button>
    </div>)
}

export default Login;