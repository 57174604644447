import { useEffect, useState } from 'react';
import './CurrentlyFree.css'
import Clock from './Clock';
import { book, getCalenderEntries, getTodaysEntries } from '../00_Function/CalenderEntries';
import { createBooking } from '../00_Function/Booking';


const startBooking = async (duration, setEntries, setSuccess, calender, setErrorOrOK) => {
    let startTime = new Date();
    let minutes = startTime.getMinutes() + 1;
    startTime.setMinutes(minutes);
    startTime.setSeconds(0)
    
    let endTime = new Date(startTime.getTime()+duration*60000);
    console.log(`START: ${startTime.toISOString()} - END: ${endTime.toISOString()}`)
    let suc = await createBooking(startTime,endTime, calender, setErrorOrOK);
    let {todayStart, todayEnd} = getTodaysEntries();
    getCalenderEntries(setEntries, setSuccess, true, calender)
}

const CurrentlyFree = props => {
    let [errorOrOK, setErrorOrOK] = useState("");
    useEffect(()=>{
        console.log({errorOrOK})
        if(errorOrOK === "") return;
        setTimeout(() => {
            setErrorOrOK("");
        }, 5000)
    }, [errorOrOK])

    return (<div className='currentlyFree'>
        <Clock 
            occupied={true}
            time ={props.time}/>
        <h1>Frei</h1>

        <h3>Ad-hoc Buchung:</h3>
        <div className='adHocBooking'>
            <button onClick={() => startBooking(15, props.setEntries, props.setSuccess, props.activeCalendar.id, setErrorOrOK)}>15min</button>
            <button onClick={() => startBooking(30, props.setEntries, props.setSuccess, props.activeCalendar.id, setErrorOrOK)}>30min</button>
            <button onClick={() => startBooking(45, props.setEntries, props.setSuccess, props.activeCalendar.id, setErrorOrOK)}>45min</button>
            <button onClick={() => startBooking(60, props.setEntries, props.setSuccess, props.activeCalendar.id, setErrorOrOK)}>1h</button>
        </div>
        <div className='bookingError'>
            <h3>{errorOrOK}</h3>
        </div>
    </div>)
}

export default CurrentlyFree;